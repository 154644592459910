
import React, {useState, useEffect} from "react"
import {Link, navigate} from "gatsby"

import Layout from "../components/layout"

import authmenulist from "../content/authmenulist.json";

import * as styles from "../styles/pages/home.module.css"

import imgmenusearch from "../images/menu-search.png"
import imgmenuclose from "../images/menu-close.png"
import imgscanqr from "../images/buttons/scan_qr.png"

import QRScanner from "../components/controls/qrscanner";

const SEARCHMENUMINIMUM=3;

const HomePage = ({location}) => {
	const [token, setToken] = useState("");
	const [scanpath, setScanpath] = useState("");
	const [scanmode, setScanmode] = useState(false);
	const [userinfo, setUserinfo] = useState({});
	const [searchkey, setSearchkey] = useState("");

	useEffect(()=> {
		if (token.length < 1) {
			return;
		}
		const querylist = ["search", "hash"];
		var queryparam = {};
		var querystring = "";
		var querystrdivider = "";
		var tmpidx = 0;

		while (tmpidx < querylist.length) {
			if (location.hasOwnProperty(querylist[tmpidx])) {
				if (location[querylist[tmpidx]]) {
					querystring = querystring + querystrdivider + location[querylist[tmpidx]].substring(1);
					querystrdivider = "&";
				}
			}
			tmpidx++;
		}
		if (querystring.length > 0) {
			const tmpparamlist = Array.from(new URLSearchParams(querystring));
			tmpidx = 0;
			while (tmpidx < tmpparamlist.length) {
				if (tmpparamlist[tmpidx][1] !== null) {
					queryparam[tmpparamlist[tmpidx][0]] = tmpparamlist[tmpidx][1];
				}
				tmpidx++;
			}
		}
		if (queryparam.hasOwnProperty("scan")) {
			setScanmode(true);
			querystring = "/";
			if (queryparam.hasOwnProperty("source")) {
				if (queryparam.source.length > 0) {
					querystring = queryparam.source;
				}
			}
			setScanpath(querystring);
		}

	}, [location, token])


	const scanHandler = function(data, success)
	{
		if (success) {
			//setTeststr(data);
		} else if (scanpath.length > 0) {
			navigate(scanpath);
		}
		setScanmode(false);
	}


	const searchKeyChange = (e) => {
		setSearchkey(e.target.value.toLowerCase());
	}

	const renderparent = (key, entry) => {
		const showsection = (entry.title.toLowerCase().indexOf(searchkey)>=0 && !entry.subitems) || (entry.subitems &&JSON.stringify(entry.subitems).toLowerCase().indexOf(searchkey)>=0);
		if (!showsection) {
			return <></>
		}
		if (entry.hasOwnProperty("subitems")) {
			return <div className={styles.homecontentsublist}>
				<h2>{entry.title}</h2>
				<hr/>
				{entry.subitems.map((subentry, idx)=> {return renderparent(key+"-"+idx, subentry)})}
				<hr/>
			</div>
		} else {
			return <Link
					to={"/"+entry.url}
				>
					{entry.title}
				</Link>

		}
	}

	const curmenu = userinfo.hasOwnProperty("accessid")?(authmenulist["access"+userinfo.accessid]?authmenulist["access"+userinfo.accessid]:[]):[];

	var samplesearchtitle = "";
	var totalidx = 0;
	var primaryidx = 0;
	var secondaryidx = 0;
	while (primaryidx < curmenu.length) {
		if (curmenu[primaryidx].hasOwnProperty("subitems")) {
			secondaryidx = 0;
			while (secondaryidx < curmenu[primaryidx].subitems.length) {
				samplesearchtitle = curmenu[primaryidx].subitems[secondaryidx].title;
				totalidx++;
				secondaryidx++;
			}
		} else {
			samplesearchtitle = curmenu[primaryidx].title;
			totalidx++;
		}

		primaryidx++;
	}

	return <Layout fullPath={location.pathname}
			newUserHandler={(newtoken, newuserinfo)=>{setToken(newtoken); setUserinfo(newuserinfo)}}
			privatePage={true} usegatedcontent={true}>
			{totalidx > 0 ? <>
				<QRScanner scanning={scanmode} resultcallback={scanHandler} />
				<h1 className={styles.homewelcome+" font-size-medium text-centered"}>Hi {userinfo.name}</h1>
				{totalidx > SEARCHMENUMINIMUM && <div className={styles.homesearchholder+" text-centered"}>
					<div className={styles.homesearch}>
						<input className="font-size-large" type={"text"} placeholder={"Menu Search"} value={searchkey} onChange={searchKeyChange} onBlur={searchKeyChange} />
						{searchkey !== "" &&
							<button title={"Clear"} className={styles.homesearchclear+" iconbutton"} onClick={(e)=>{setSearchkey("");}}>
								<img src={imgmenuclose} alt={"Clear"} />
							</button>
						}
						<button title={"Search"} className={"iconbutton"}>
							<img src={imgmenusearch} alt={"Search"} />
						</button>
					</div>
				</div>}
				<div className={styles.homecontent+" margin-content"}>
					{curmenu.map((entry, idx)=> {
						return renderparent("main"+idx, entry);
					})}
				</div>
			</>
			:<p class="underconstruction font-size-large">
				Loading...
			</p>
			}

		</Layout>

}


export default HomePage;
